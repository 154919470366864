<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <div class="col-sm-12 my-5 px-4 text-center">
        <b-img
          src="/CU2_Logo.png"
          fluid
          alt="CU2.0 Logo"
          class="logo mx-auto"
          v-if="isPartnerSite"
        />
        <b-img
          src="/logo.png"
          fluid
          alt="FINavigator Logo"
          class="logo mx-auto"
          v-else
        />
      </div>
      <div class="container user-register-page">
        <b-row class="justify-content-center">
          <b-col sm="12" md="12" lg="6">
            <b-card no-body class="mx-4">
              <b-card-body class="p-4">
                <h1>Invite Register</h1>
                <p class="text-muted">Complete your profile</p>
                <b-row>
                  <b-col sm="12" md="6">
                    <b-input-group>
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fa fa-user"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': $v.user.first_name.$error,
                          'is-valid': !$v.user.first_name.$invalid
                        }"
                        placeholder="First Name"
                        v-model="user.first_name"
                        @blur="$v.user.first_name.$touch()"
                      />
                      <b-form-invalid-feedback
                        v-if="!$v.user.first_name.required"
                        >First Name can't be blank</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="!$v.user.first_name.alphaNum"
                        >First Name can be only alphanumeric
                        characters</b-form-invalid-feedback
                      >
                      <!-- <b-form-invalid-feedback v-if="!$v.user.first_name.minLen"
                        >First Name must be of minimum 3 characters
                        length</b-form-invalid-feedback
                      > -->
                    </b-input-group>
                  </b-col>
                  <div class="d-block d-md-none" v-html="'&nbsp;'"></div>
                  <b-col sm="12" md="6" class="last-name">
                    <b-input-group class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': $v.user.last_name.$error,
                          'is-valid': !$v.user.last_name.$invalid
                        }"
                        placeholder="Last Name"
                        v-model="user.last_name"
                        @blur="$v.user.last_name.$touch()"
                      />
                      <b-form-invalid-feedback
                        v-if="!$v.user.last_name.required"
                        >Last Name can't be blank</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="!$v.user.last_name.alphaNum"
                        >Last Name can be only alphanumeric
                        characters</b-form-invalid-feedback
                      >
                      <!-- <b-form-invalid-feedback v-if="!$v.user.last_name.minLen"
                        >Last Name must be of minimum 3 characters
                        length</b-form-invalid-feedback
                      > -->
                    </b-input-group>
                  </b-col>
                </b-row>

                <b-input-group class="mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="email"
                    readonly="true"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.email.$error,
                      'is-valid': !$v.user.email.$invalid
                    }"
                    placeholder="Email"
                    v-model="user.email"
                    @blur="$v.user.email.$touch()"
                  />
                  <b-form-invalid-feedback v-if="!$v.user.email.required"
                    >Email can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.user.email.email">
                    Please provide a valid email
                    address</b-form-invalid-feedback
                  >
                </b-input-group>

                <b-input-group class="mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-key"></i
                    ></span>
                  </div>
                  <input
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.password.$error,
                      'is-valid': !$v.user.password.$invalid
                    }"
                    placeholder="Password"
                    v-model="user.password"
                    @blur="$v.user.password.$touch()"
                  />
                  <b-form-invalid-feedback v-if="!$v.user.password.required"
                    >Password can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback v-if="!$v.user.password.minLen"
                    >Password must be of minimum 4 characters
                    length</b-form-invalid-feedback
                  >
                </b-input-group>

                <b-input-group class="mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-key"></i
                    ></span>
                  </div>
                  <input
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.repeat_password.$error,
                      'is-valid': !$v.user.repeat_password.$invalid
                    }"
                    placeholder="Repeat password"
                    v-model="user.repeat_password"
                    @blur="$v.user.repeat_password.$touch()"
                  />
                  <b-form-invalid-feedback
                    v-if="!$v.user.repeat_password.sameAs"
                    >Password and Repeated Password are not
                    matching</b-form-invalid-feedback
                  >
                </b-input-group>

                <b-input-group class="mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-institution"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    :readonly="!companyEditable"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.company.$error,
                      'is-valid': !$v.user.company.$invalid
                    }"
                    placeholder="Company"
                    v-model="user.company"
                    @blur="$v.user.company.$touch()"
                  />
                  <b-form-invalid-feedback v-if="!$v.user.company.required"
                    >Company can't be blank</b-form-invalid-feedback
                  >
                  <!-- <b-form-invalid-feedback v-if="!$v.user.company.minLen"
                    >Company must be of minimum 3 characters
                    length</b-form-invalid-feedback
                  > -->
                </b-input-group>

                <b-input-group class="mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-crosshairs"></i
                    ></span>
                  </div>
                  <multiselect
                    v-model="user.company_type"
                    :options="companyTypes"
                    :multiple="false"
                    :close-on-select="true"
                    placeholder="Select Company Type"
                    group-label="type"
                    group-values="companies"
                    :class="[
                      {
                        'is-invalid': $v.user.company_type.$error,
                        'is-valid': !$v.user.company_type.$invalid
                      },
                      'simple-select group-select highlight-group-title form-control'
                    ]"
                    :group-select="false"
                    :showLabels="false"
                    :disabled="true"
                    @close="validateCompanyType()"
                  />
                  <b-form-invalid-feedback v-if="!$v.user.company_type.required"
                    >Company Type can't be blank</b-form-invalid-feedback
                  >
                </b-input-group>
                <b-input-group class="mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-graduation-cap"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.title.$error,
                      'is-valid': !$v.user.title.$invalid
                    }"
                    placeholder="Title"
                    v-model="user.title"
                    @blur="$v.user.title.$touch()"
                  />
                  <b-form-invalid-feedback v-if="!$v.user.title.required"
                    >Title can't be blank</b-form-invalid-feedback
                  >
                  <!-- <b-form-invalid-feedback v-if="!$v.user.title.minLen"
                    >Title must be of minimum 3 characters
                    length</b-form-invalid-feedback
                  > -->
                </b-input-group>
                <b-input-group class="mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-phone"></i
                    ></span>
                  </div>
                  <cleave
                    placeholder="Phone"
                    :options="phoneOptions"
                    v-model="user.phone"
                    class="form-control"
                    :class="{
                      'is-invalid': $v.user.phone.$error,
                      'is-valid': !$v.user.phone.$invalid
                    }"
                    @blur="$v.user.phone.$touch()"
                  ></cleave>
                  <b-form-invalid-feedback v-if="!$v.user.phone.required"
                    >Phone can't be blank</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback
                    v-if="!$v.user.phone.mustMatchPhoneLength"
                    >Phone number is not valid.</b-form-invalid-feedback
                  >
                </b-input-group>
                <b-input-group class="mb-3 terms-conditions">
                  <b-form-checkbox
                    id="terms"
                    v-model="terms"
                    checked
                    @click="$v.terms.$touch()"
                  >
                    I agree to the
                    <a
                      href="javascript:void(0)"
                      @click="toggleTermsConditions()"
                      >Terms of Service.</a
                    >
                  </b-form-checkbox>
                  <b-form-invalid-feedback v-if="!$v.terms.required"
                    >You must agree to the terms and
                    conditions.</b-form-invalid-feedback
                  >
                </b-input-group>
                <b-button
                  variant="success"
                  @click="acceptInvite"
                  block
                  :disabled="$v.$invalid || submitting"
                  >{{ submitText() }}</b-button
                >
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <b-modal id="termsConditions" size="lg" v-model="showTC" hide-header>
        <div class="modal-content">
          <b-card-header header-class="vendor-card">
            <h5 class="text-center"><b>TERMS OF SERVICE</b></h5>
          </b-card-header>
          <b-card-body>
            <TermsOfService></TermsOfService>
          </b-card-body>
        </div>
        <div slot="modal-footer" class="w-100 text-center">
          <button
            type="button"
            class="btn btn-secondary fw-btn"
            @click="showTC = false"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary fw-btn"
            @click="
              terms = true
              showTC = false
            "
          >
            Agree
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

import {
  required,
  email,
  minLength,
  alphaNum,
  sameAs
} from 'vuelidate/lib/validators'
import TermsOfService from '@/modules/authentication/pages/terms_of_service'
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'InviteRegister',
  components: {
    TermsOfService,
    Multiselect,
    Cleave
  },
  created() {
    if (this.currentUserEmail) {
      Vue.toasted.show(
        'Already logged in. Please logout and click on verification link.',
        { icon: 'key', type: 'error' }
      )
      this.$router.push('/')
    } else {
      this.fetchUserInfo()
    }
  },
  data() {
    return {
      user: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        repeat_password: '',
        company: '',
        company_type: null,
        title: '',
        phone: '',
        is_approved: true
      },
      companyEditable: false,
      submitting: false,
      terms: true,
      showTC: false,
      phoneOptions: {
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-'
      }
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters(['companyTypes']),

    isPartnerSite() {
      return window.location.host === process.env.VUE_APP_ETHOS_URL_NAME
    }
  },
  methods: {
    fetchUserInfo() {
      this.$http
        .get('/api/group_users/user_info', {
          params: {
            token: this.$route.query.token
          },
          handleErrors: true
        })
        .then(
          res => {
            this.user.id = res.data.user.id
            this.user.email = res.data.user.email
            this.user.company = res.data.user.company
            this.user.company_type = res.data.user.company_type
            this.user.confirmation_token = this.$route.query.token
            this.companyEditable = res.data.company_editable
          },
          error => {
            if (error.response.status === 404) {
              Vue.toasted.show(
                'Email token invalid/expired. Please contact admin.',
                {
                  icon: 'chain-broken',
                  type: 'error'
                }
              )
            } else {
              Vue.toasted.show(
                'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
                {
                  icon: 'chain-broken',
                  type: 'error'
                }
              )
            }

            this.$router.push({ name: 'SignIn', params: { silent: true } })
          }
        )
    },
    acceptInvite: function() {
      if (this.user.phone.replaceAll('-', '').length > 11) {
        Vue.toasted.show('Please enter a valid Phone number', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      this.submitting = true
      this.$http
        .put(`/api/group_users/${this.user.id}/accept_invite`, this.user, {
          handleErrors: true
        })
        .then(res => {
          this.submitting = false
          if (res.data.user) {
            Vue.toasted.show(
              'Data updated successfully, please sign in to continue',
              { icon: 'user-circle', type: 'success' }
            )
            this.$router.push('/sign_in')
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        })
    },
    submitText: function() {
      return this.submitting ? 'Updating ...' : 'Accept Invite'
    },
    toggleTermsConditions: function() {
      this.showTC = !this.showTC
    },
    validateCompanyType() {
      this.$v.user.company_type.$touch()
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      first_name: {
        required,
        alphaNum
        // minLen: minLength(3)
      },
      last_name: {
        required,
        alphaNum
        // minLen: minLength(3)
      },
      password: {
        required,
        minLen: minLength(4)
      },
      repeat_password: {
        required,
        sameAs: sameAs(vm => {
          return vm.password
        })
      },
      company: {
        required
        // minLen: minLength(3)
      },
      company_type: {
        required
      },
      title: {
        required
        // minLen: minLength(3)
      },
      phone: {
        required,
        mustMatchPhoneLength(value) {
          if (value) {
            let phone = value.replaceAll('-', '')
            return phone.startsWith('1')
              ? phone.length === 11
              : phone.length === 10
          } else {
            return false
          }
        }
      }
    },
    terms: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.btn.disabled {
  cursor: default;
}

.disable-content {
  color: red;
}

.terms-conditions {
  label {
    margin-bottom: 0;
  }
  .invalid-feedback {
    display: block;
  }
}
</style>

<style lang="scss">
#termsConditions .modal-footer {
  border-top: none;
}
.logo {
  width: 300px;
}
</style>
